html, body {
  margin: 0;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: overlay;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-height: 100%;
  max-width: 100%;
  padding: 0;
  object-fit: contain;
  display: block;
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
}

@media screen and (min-width: 900px) {
  .nav-right {
    border-right: 2px solid white;
  }
}

@media screen and (max-width: 900px) {
  .nav-right {
    border-bottom: 2px solid white;
  }
}

@media screen and (min-width: 600px) {
  .nav-item {
    font-size: 4vw;
    -webkit-text-stroke: 2px white;
    background: linear-gradient(white,white) left no-repeat,
    black;
    background-size:0% 100%;
    display: block;
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.5s;
  }
}

.nav-item:hover {
  background-size: 100% 100%;
}

@media screen and (max-width: 600px) {
  .nav-item {
    font-size: 4vw;
    color: white;
  }
}

.nav-item:hover, .nav-item:active, .social-link, .contact-text-field input, .contact-text-field textarea {
  color: white;
}

.burger div {
  height: 3px;
  background-color: #000;
  margin: 5px 0px;
  border-radius: 0;
  transition: 0.3s;
}

.burger:hover div {
  width: 30px;
}

.burger {
  display: block;
  width: 30px;
}

.burger-one {
  width: 30px;
}

.burger-two {
  width: 20px;
}


.marquee {
  font-weight: bold;
  font-size: 6vw;
}

@media screen and (min-width: 600px) {
  .marquee-item:nth-child(even) {
    -webkit-text-stroke: 2px black;
    color: white;
  }
}

.contact-text-field fieldset, .contact-text-field:focus fieldset {
  border: 2px solid white !important;
  border-radius: 0;
}

.contact-label {
  color: white !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
}

.project-text-field fieldset, .project-text-field:focus fieldset {
  border: 2px solid black !important;
  border-radius: 0;
}

.project-text-field {
  width: 60vw;
}

.project-label {
  color: black !important;
  font-weight: bold !important;
  margin-bottom: 5px !important;
}

.MuiInputBase-input {
  padding: 8.5px 14px !important;
}

#password-input::placeholder {
  opacity: 100%;
  -webkit-text-stroke: 2.5px black;
  color: white;
  font-weight: bold;
}

.social-link-loading {
  color: black;
  cursor: none;
}

#loading {
  cursor: none !important;
  background-color: #fff;
}

.cursor {
  pointer-events: none;
  position: fixed;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  mix-blend-mode: difference;
  transition: width 0.5s, height 0.5s;
  transform: translate(-50%, -50%);
}

.cursor.grow {
  width: 60px;
  height: 60px;
  transition: width 0.5s, height 0.5s;
}

/* .cursor-border {
  pointer-events: none;
  position: fixed;
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 50%;
  mix-blend-mode: difference;
  transform: translate(-50%, -50%);
} */

#about {
  background-image: url('./assets/images/sky.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.3s;
}

.tech-pill {
  border: 1px solid white;
  border-radius: 30px;
  padding: 10px 20px; 
  margin-right: 10px; 
  margin-top: 20px;
  display: inline-block;
  font-weight: bold;
  cursor: default;
}

.tech-pill:hover {
  background-color: white;
  color: black;
}

.not-found-pill {
  border: 3px solid white;
  border-radius: 55px;
  padding: 10px 20px; 
  display: inline-block;
}

.preview-outline {
  -webkit-text-stroke: 2px white;
  color: transparent;
}

#preview {
  background-image: url('./assets/images/preview.png');
  background-position: right 40px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  opacity: 1.0;
}
    